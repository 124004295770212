.layout {
  background-color: var(--color-light-90);
  display: flex;
  height: 100vh;
  width: 100wv;
}

.container {
  margin: auto;
  width: 750px;
  display: flex;
  justify-content: space-between;
}

.title {
  text-align: center;
  margin: 16px 0 0 0;
  font-weight: 700;
  font-size: 46px;
}

.logo {
  margin-top: 35px;
}

.loginFormWrapper {
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-light-100);
  box-shadow: 0px 4px 40px rgba(193, 168, 163, 0.26);
  border-radius: 18px;
}
