.container {
  z-index: 20;
  width: 280px;
  min-height: 50px;
  padding: 18px 50px 18px 24px;
  position: fixed;
  top: 50px;
  right: -320px;
  display: flex;
  justify-content: left;
  align-items: center;
  border-radius: 10px;
  color: var(--color-light-100);
  overflow-wrap: anywhere;
  transition: right 0.6s cubic-bezier(0.23, 1, 0.320, 1)
}

.active {
  right: 25px;
}

.close {
  position: absolute;
  display: flex;
  width: 18px;
  height: 18px;
  top: 10px;
  right: 10px;
  padding: 3px;
  cursor: pointer;

  svg {
    fill: var(--color-light-100)
  }
}

.type-error {
  background: var(--color-notification-error);
}
