.backdrop {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: var(--color-background-backdrop);
  backdrop-filter: blur(4px);
  z-index: 10;
}

.content {
  // min-width: 250px;
  // min-height: 190px;
  padding: 50px 20px 20px;
  background-color: var(--color-light-100);
  border: 1px solid var(--color-border);
  box-shadow: 0 4px 26px rgba(139, 129, 129, 0.15);
  border-radius: 8px;
  position: relative;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
}

.close {
  display: flex;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
