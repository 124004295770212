.container {
  display: flex;
  gap: 14px;
  align-items: center;
  position: relative;

  input[type=file] {
    padding-top: 5px;
    color: var(--color-grey-90);

    &::file-selector-button {
      display: none;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.thumbnail {
  position: absolute;
  height: 120px;
  left: 300px;
  top: -130px;
  border-radius: 6px;
  box-shadow: 0 4px 18px rgba(134, 139, 157, 0.21);
}
