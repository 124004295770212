@use 'styles/mixins.scss';

.customizeTable {
  height: 661px;

  th:nth-child(4), th:nth-child(6) {
    border: none;
    padding-right: 8px;
  }
  th:nth-child(5), th:nth-child(7) {
    padding-left: 8px;
  }
  tr td:nth-child(4), tr td:nth-child(6) {
    padding-right: 8px;
  }
  tr td:nth-child(5), tr td:nth-child(7) {
    padding-left: 8px;
  }
  tr td:nth-child(2) {
    color: var(--color-grey-90)
  }
  tr:has(td div [data-bannerid]) {
    background-color: var(--color-table-row-hover);
  }
}