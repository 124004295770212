.container {
  min-height: 34px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 0;
  border-radius: 6px;
  transition: background-color 0.2s ease-in-out;
}

.container:hover {
  background-color: var(--color-menuItem-hover);
  cursor: pointer;
}

.text {
  margin-left: 16px;
  font-size: 18px;
}

.disabled {
  color: var(--color-grey-90);
  &>svg path {
    color: var(--color-grey-90);
  }
}

.icon {
  display: flex;
  align-items: center;
  margin-left: 12px;
  margin-right: -4px;
}

.content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
