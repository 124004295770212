$color: #F16437, #FF916D, #F14237, #FF4D77, #FF6A6A;

body {
  overflow: hidden;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  overflow: hidden;
}

$circle-size: 7px;
$circle-margin: 3px;

.circle {
  width: $circle-size;
  height: $circle-size;
  border-radius: 50%;
  margin: $circle-margin;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: "";
    width: $circle-size;
    height: $circle-size;
    border-radius: 50%;
    opacity: 0.7;
    animation: scale 2s infinite
    cubic-bezier(0, 0, 0.49, 1.02);
  }
}

@for $i from 1 through 5 {
  .circle-#{$i} {
    background-color: nth($color, $i);
    &:before {
      background-color: nth($color, $i);
      animation-delay: 200ms * $i;
    }
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50%, 75% {
    transform: scale(2.5);
  }
  78%, 100% {
    opacity: 0;
  }
}