.layoutContainer {
  background-color: var(--color-light-90);
}

.pageContainer {
  width: calc(100% - 310px);
  position: absolute;
  top: 10px;
  left: 285px
}
