@use 'styles/mixins.scss';

.container {
  width: 100%;
  position: relative;
}

.errorMessage {
  @include mixins.errorMessage;
  position: absolute;
  margin-top: 6px;
  margin-bottom: 12px;
  text-align: left;
}
