.paginator {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 25px 0;
  font-size: 18px;
  font-weight: 400;

  .controlBlock {
      display: flex;
      align-items: center;
  }

  .perPageSelect {
      width: 60px;
      margin: 0 12px;
  }

  .currentPageInput {
      width: 32px;
      margin: 0 12px;
      padding: 0;
      text-align: center;
  }

  .controlBtn {
      margin-left: 12px;
      padding: 0;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
  }
}
