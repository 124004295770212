@use 'styles/mixins.scss';

.container {
  @include mixins.endEllipsis;
  color: var(--color-grey-90);
}

.active {
  color: var(--color-text);
  font-weight: 600;
}
