@use 'styles/mixins.scss';

.container {
  @include mixins.mainFont;
  height: 100%;
  cursor: pointer;
  font-weight: 400;
  white-space: pre;
  transition: all 0.2s;
}

.size-32 {
  padding: 0 16px;
  border-radius: 8px;
  font-size: 16px;
  line-height: 30px;
}

.size-46 {
  padding: 0 40px;
  border-radius: 20px;
  font-size: 22px;
  line-height: 44px;
}

.variant-primary {
  background-color: var(--color-button-primary);
  border: 1px solid var(--color-button-primary);
  color: var(--color-light-100);

  &:hover {
    background-color: var(--color-button-primary-hover);
    border: 1px solid var(--color-button-primary-hover);
  }

  &:active {
    background-color: var(--color-button-primary-active);
    border: 1px solid var(--color-button-primary-active);
  }

  &.disabled {
    background-color: var(--color-button-disabled);
    border: 1px solid var(--color-button-disabled);
    cursor: inherit;
  }
}

.variant-secondary {
  background-color: transparent;
  border: 1px solid transparent;
  color: var(--color-dark-80);

  &:hover {
    background-color: var(--color-light-100);
    border: 1px solid var(--color-button-secondary-hover);
    color: var(--color-button-secondary);
  }

  &:active {
    background-color: var(--color-light-100);
    border: 1px solid var(--color-button-secondary-hover);
    color: var(--color-button-secondary);
    box-shadow: inset 0px 2px 10px rgba(89, 78, 68, 0.22);
  }

  &.disabled {
    color: var(--color-button-disabled);
    border: 1px solid transparent;
    box-shadow: none;
    cursor: inherit;

    & svg * {
      stroke: var(--color-button-disabled);
    }
  }
}

.variant-text {
  background-color: transparent;
  border: none;
  padding: 0;
  border-radius: 0;
  line-height: normal;
  height: fit-content;
  color: var(--color-grey-90);
  border-bottom: 1px solid;

  &:hover {
    color: var(--color-dark-80);
  }

  &:active {
    color: var(--color-dark-80);
  }

  &.disabled {
    color: var(--color-button-disabled);
    cursor: inherit;
  }

  &.active {
    color: var(--color-dark-80);
    border-bottom: 1px solid transparent;
  }
}

.variant-icon {
  background-color: transparent;
  border: none;
  padding: 0;
  border-radius: 0;
  line-height: normal;

  svg>path {
    stroke: var(--color-grey-90);
  }

  &:active {
    svg>path {
      stroke: var(--color-dark-80);
    }
  }
}

.block {
  width: 100%;
}
