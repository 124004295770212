.container {
  width: 600px;
  display: grid;
  grid-gap: 4px;
  min-height: 184px;
  grid-template-columns: repeat(25, 20px);
  grid-template-rows: repeat(8, 20px);
  cursor: pointer;
}

.dayLabel, .timeLabel {
  justify-self: center;
  align-self: center;
}

.weekdays {
  color: var(--color-grey-90);
}
