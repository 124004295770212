.container {
  width: 140px;
  text-align: center;
  padding: 3px 10px;
  border-radius: 8px;
}

.state-started {
  background: var(--color-tag-started);
  border: 1px solid var(--color-tag-started-border);
}

.state-paused {
  background: var(--color-tag-paused);
  border: 1px solid var(--color-tag-paused-border);
}
