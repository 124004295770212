@use 'styles/mixins.scss';

.container {
  min-height: 77px;
  white-space: nowrap;
}

.title {
  @include mixins.title;
  text-decoration: none;
  display: inline-block;
}

.lastCrumb {
  pointer-events: none;
}
