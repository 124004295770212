:root {
  --color-light-100: #FFF;
  --color-light-90: #FFFDFB;

  --color-dark-100: #000;
  --color-dark-80: #282523;

  --color-grey-90: #8B8B8B;

  --color-text: #282523;
  --color-text-brown: #571C06;
  --color-text-red: #E5360F;
  --color-text-orange: #FF6E30;

  --color-background-error: #FFEED3;
  --color-background-backdrop: rgba(147, 142, 142, 0.42);

  --color-menuItem-hover: #FFEED3;
  --color-menuItem-active: #FFDDBD;

  --color-button-primary: #F16437;
  --color-button-primary-hover: #DF4210;
  --color-button-primary-active: #C73B0F;
  --color-button-secondary: #E5360F;
  --color-button-secondary-hover: #EADFD4;
  --color-button-disabled: #D8D3CE;

  --color-tag-started: #F6FFE7;
  --color-tag-started-border: #B7DB6B;
  --color-tag-paused: #FFFDE7;
  --color-tag-paused-border: #E1E35C;

  --color-input-bg: #F8F3F0;
  --color-input-border: #F0EFEE;
  --color-input-focus: #FDC7B6;
  --color-input-invalid: #F58374;

  --color-notification-error: #AC170A;

  --color-border: #E4E0DB;

  --color-template-bg: #FFFAF5;

  --color-calendar-header: #FFF6E7;
  --color-calendar-selected: #FFE4DB;
  --color-calendar-selected-hover: #FDC7B6;

  --color-table-row-hover: #FAF8F7;

  --color-checkbox-checked: #2A2521;

  --color-statistic-banner-background: #FFF9F3
}
