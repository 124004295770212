.dropdown {
  width: max-content;
  max-height: 284px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 6px 0;
  background: var(--color-light-100);
}

.dropdownItem {
  margin-right: 30px;
  border-radius: 0;
}

.warningItem div {
  color: var(--color-text-red)
}

.activeItem {
  background-color: var(--color-menuItem-active);
}
