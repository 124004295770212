@use 'styles/mixins.scss';

.container {
  @include mixins.standardForm;
}

.variants {
  display: flex;
  label {
    margin-right: 30px;
  }
}

.fileUploader {
  width: 506px;
}


