@use 'styles/mixins.scss';

.form {
  position: relative;

  .container {
    display: flex;
    gap: 14px;
    align-items: center;
  }

  .input {
    width: 210px;
  }
}
