.backdrop {
  background-color: var(--color-background-backdrop);
  display: flex;
  width: 100vw;
  height: 100vh;
}

.container {
  width: 500px;
  min-height: 185px;
  position: relative;
  margin: auto;
  border-radius: 16px;
  background-color: var(--color-background-error);
}

.title {
  margin: 36px 0 0 28px;
  font-size: 20px;
  font-weight: 700;
  color: var(--color-text-red);
}

.description {
  margin: 12px 0 0 28px;
  font-size: 18px;
}

.button {
  font-size: 18px;
  color: var(--color-text);

  &:hover {
    color: var(--color-grey-90);
  }
}

.image {
  position: absolute;
  bottom: 0;
  right: 0;
}