@use 'styles/mixins.scss';

.container {
  position: relative;
}

.questionIcon {
  display: flex;
  align-items: center;
  margin: 0 6px 0 -6px;
  cursor: pointer;
}

.tooltip {
  @include mixins.tooltip;
  position: absolute;
  transform-origin: bottom;
  transform: scaleY(0);
  width: max-content;
  z-index: 1;
  white-space: pre-line;
  transition: all 0.2s;
}

.container:hover .tooltip {
  transform: scale(1);
}

.arrow::before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 3px  ;
  transform: translate(-8px, 8px) rotate(45deg);
  transform-origin: center;
  background-color: var(--color-menuItem-hover);
}

.tooltip[data-popper-placement^='bottom-start'] > .arrow {
  top: -5px;
}
