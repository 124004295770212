@use 'styles/mixins.scss';

.container {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.labelLogin, .labelPassword {
  width: 100%;
  margin-top: 32px;
  margin-bottom: 12px;
  font-size: 18px;
  text-align: left;
}

.labelLogin {
  margin-top: 0;
}

.loginButton {
  margin-top: 25px;
}

.errorMessage {
  @include mixins.errorMessage;
  margin-top: 6px;
  text-align: center;
}