.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
}

.link {
  text-decoration: none;
  margin-bottom: 8px;
}

.disabled {
  pointer-events: none;
}

.activeLink {
  background-color: var(--color-menuItem-active);
  border-radius: 6px;
}
