.container {
  display: flex;
  align-items: center;
  position: relative;
  height: 31px;
  width: fit-content;
  padding-left: 43px;
  margin-bottom: 12px;
  margin-right: 60px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  color: var(--color-grey-90);
  &.checked {
    color: var(--color-checkbox-checked);
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 31px;
    width: 31px;
    background-color: var(--color-light-100);
    border: 1px solid var(--color-dark-80);
    border-radius: 8px;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 11px;
      top: 4px;
      width: 5px;
      height: 15px;
      border: solid var(--color-button-primary);
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}
