@use 'styles/mixins.scss';

.label {
  font-size: 18px;
  text-align: left;
  margin: 0 0 8px;

  &:nth-child(4) {
    margin: 25px 0 8px;
  }
}

.block {
  display: flex;
  align-items: flex-end;
}

.textarea {
  @include mixins.mainFont;
  width: 310px;
  padding: 10px;
  margin-right: 18px;
  box-sizing: border-box;
  border: 1px solid var(--color-input-border);
  border-radius: 10px;
  font-size: 14px;
  resize: none;
  color: var(--color-grey-90);

  &::placeholder {
    color: var(--color-grey-90);
  }

  &:focus {
    outline: none;
    border: 1px solid var(--color-input-focus);
  }
}