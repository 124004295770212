@use 'styles/mixins.scss';

.container {
  @include mixins.standardForm;
}

.rotationTypeContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.dateFrom {
  margin-bottom: 12px;
}
