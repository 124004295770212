@mixin commonFont {
  font-size: 16px;
  font-family: 'PT Sans';
}

.wrapper {
  @import 'node_modules/react-datepicker/src/stylesheets/datepicker-cssmodules.scss';

  :global .react-datepicker__header {
    background-color: var(--color-calendar-header);
    border: none;
  }
  
  :global .react-datepicker__input-time-container {
    display: none;
  }

  :global .react-datepicker__day--keyboard-selected {
    background-color: transparent;
    color: var(--color-text);
  }

  :global .react-datepicker__current-month {
    @include commonFont;
    text-transform: capitalize;
  }

  :global .react-datepicker__day--in-range {
    background-color: var(--color-calendar-selected);
    color: var(--color-text);
  }

  :global .react-datepicker__day--in-selecting-range:not(.wrapper .react-datepicker__day--in-range) {
    background-color: var(--color-calendar-selected);
    color: var(--color-text);
  }


  [aria-selected=true] {
    color: var(--color-text);
    background-color: var(--color-calendar-selected);
    border-radius: 6px;

    &:hover {
      background-color: var(--color-calendar-selected-hover);
    }
  }

  [aria-selected=false] {
    &:hover {
      background-color: var(--color-calendar-selected-hover);
    }
  }
}

.dateInputContainer {
  //width: 203px;
}

.calendarContainer {
  .calendar {
    border-radius: 12px;
    border: none;
    box-shadow: 0 4px 26px rgba(139, 129, 129, 0.15);
  }
}

.days {
  @include commonFont;
  user-select: none;
}

.weekdays {
  @include commonFont;
}

