@use 'styles/mixins.scss';

.title {
  @include mixins.title;
  text-align: left;
  margin: 10px 0 25px;
}

.modalTitle {
  font-size: 20px;
  margin: 30px 0 35px;
}

.mirrorsList {
  margin-top: 35px;
  max-height: 200px;
  overflow-y: auto;
}

.mirror:hover {
  cursor: inherit;
}

.delButton:hover {
  background-color: transparent;
  border-color: transparent;
}