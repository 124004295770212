@use 'styles/mixins.scss';

.container {
  @include mixins.input;
}

.size-32 {
  height: 32px;
  background: var(--color-light-100);
  border-radius: 8px;
}

.size-42 {
  height: 42px;
  background: var(--color-input-bg);
  border-radius: 10px;
}

.invalid {
  @include mixins.invalidInput;
}

.disabled {
  color: var(--color-grey-90);
}
