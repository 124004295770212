$border: 1px solid var(--color-border);
$borderNone: 1px solid transparent;

.container {
  overflow: hidden;
}

.navigation {
  display: flex;
  position: relative;
  left: 26px;
}

.tabHead {
  border: $borderNone;
  height: 40px;
  border-radius: 8px 8px 0 0;
  position: relative;
}

.activeTabHead {
  border: $border;

  .title {
    background-color: var(--color-light-100);

    &::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: -9px;
      background-color: var(--color-light-100);
      width: calc(100% + 18px);
      height: 9px;
    }
  }

  &::before {
    content: "";
    position: absolute;
    bottom: -2px;
    left: -9px;
    width: 8px;
    height: 8px;
    border-right: $border;
    border-bottom: $border;
    border-bottom-right-radius : 8px;
    z-index: 10;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -2px;
    right: -9px;
    width: 8px;
    height: 8px;
    border-left: $border;
    border-bottom: $border;
    border-bottom-left-radius : 8px;
  }
}

.title {
  display: inline-flex;
  height: calc(100% + 1px);
  padding: 12px 26px;
  background-color: transparent;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  &:hover {
    cursor: pointer;
  }
}

.disabledTitle {
  color: var(--color-grey-90);

  &:hover {
    cursor: default;
  }
}

.tabContainer {
  width: 100%;
  min-height: 100px;
  border: $border;
  border-radius: 8px;
}

.tabContent {
  display: none;
  padding: 26px;
}

.activeTabContent {
  display: block;
}
