@use 'styles/mixins.scss';

@mixin flex-column {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.container {
  @include mixins.standardForm;

  &::placeholder {
    color: var(--color-grey-90);
  }

  &:focus {
    outline: none;
    border: 1px solid var(--color-input-focus);
  }
}

.keywordsLabel, .osLabel, .loadLimitLabel, .geoLabel {
  display: flex;
  gap: 18px;
  align-items: center;
  margin-bottom: 12px;
  margin-top: 36px;
  font-size: 18px;
  text-align: left;
}

.keywordsInput {
  width: 310px;
}

.osCheckboxGroup {
  display: grid;
  grid-template-columns: repeat(5, 120px);
}

.geoBlockWrapper, .loadLimitWrapper {
  background-color: var(--color-light-100);
  border: 1px solid var(--color-button-disabled);
  border-radius: 12px;
  width: 600px;
  padding: 20px 35px 45px 25px;
}

.geoBlock {
  display: flex;
  &:not(:first-child) {
    margin-top: 36px;
  }
}

.selectWrapper {
  width: 310px;
  @include flex-column;
}

.quickTemplateWrapper {
  width: 200px;
  margin-left: 36px;
  @include flex-column;
}

.templates {
  display: flex;
  gap: 18px;
}

.loadLimitInput {
  width: 75px;
  margin: 0 12px;
  display: inline-block;
}

.weeklyTimer {
  display: flex;
  justify-content: space-between;
  width: 600px;
  margin-bottom: 12px;
  margin-top: 36px;
  font-size: 18px;
  text-align: left;
}

.weeklyTimerLabel {
  margin-right: 18px;
}

.weeklyTimerTooltip {
  margin-right: auto;
}