.container {
  display: block;
  position: relative;
  color: var(--color-grey-90);
  cursor: pointer;

  & > input {
    display: none;
  }
}

.radioButton {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: var(--color-light-100);
  border: 1px solid var(--color-grey-90);
  border-radius: 4px;

  &:hover {
    border: 1px solid var(--color-dark-80);
  }

  &:after {
    content: "";
    position: absolute;
    display: none;
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    background: var(--color-button-primary);
    border-radius: 2px;
  }
}

.checked {
  border: 1px solid var(--color-text);

  &:after {
    display: block
  }
}

.label, .value {
  margin-left: 25px;
}
