@use 'styles/mixins.scss';

.container {
  @include mixins.input;
  padding: 10px 14px;
  border-radius: 8px;
  resize: none;
}

.invalid {
  @include mixins.invalidInput;
}
