@use 'styles/mixins.scss';
.container {
  position: relative;

  .error {
    @include mixins.errorMessage;
    position: absolute;
    left: 0;
    text-align: left;
  }
}

.checkboxGroup {
  display: flex;
}

