.container {
  position: relative;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 45%;
    right: 14px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4.5px 0 4.5px;
    border-color: var(--color-grey-90) transparent transparent transparent;
    transition: transform 0.2s;
  }

  &.selectOpen:after {
    transform: rotate(180deg);
  }

  &.clearable:after {
    display: none;
  }

  &.disabled {
    &:after {
      display: none;
    }

    & input:focus{
      border: 1px solid var(--color-input-border);
    }
  }
}

.select {
  input[readonly] {
    cursor:default
  }
}

.crossIcon {
  position: absolute;
  top: 8px;
  right: 14px;
  width: 11px;
  cursor: pointer;
  z-index: 1;
}
