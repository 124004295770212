@use 'styles/mixins.scss';

html,
body {
  @include mixins.mainFont;
  padding: 0;
  margin: 0;
  font-weight: 400;
  overflow-x: clip;
  overflow-y: visible;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  color: var(--color-text);
  box-sizing: border-box;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
