.notFound {
  height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 100px;

  .title {
    margin: 0;
    text-align: center;
    font-weight: normal;
    font-size: 144px;
    line-height: 1.2;
    @media (max-width: 425px) {
      font-size: 72px;
    }
  }

  .subtitle {
    margin: 10px 0 15px;
    text-align: center;
    @media (max-width: 1280px) {
      font-size: 16px;
    }
    @media (max-width: 425px) {
      font-size: 13px;
    }
  }

  .container {
    display: flex;
    justify-content: center;
    text-decoration: none;
  }
}
