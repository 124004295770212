@use 'styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
}

.arrowDown {
  transform: rotate(180deg);
}

.active {
  svg>path {
    stroke: var(--color-dark-80);
  }
}