@use 'styles/mixins.scss';

$tableBorder: 1px solid var(--color-border);

.container {
  height: 485px;
  overflow: auto;
  border-radius: 6px;
  background-color: var(--color-light-100);
  box-shadow: 0 4px 18px 0 #DCCDC45E;
}

.table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  //overflow: hidden;
  position: relative;
  background-color: var(--color-light-100);

  thead {
    height: 44px;

    tr {
      border-top: none;
    }
  }

  tbody {
    border-bottom: 2px solid var(--color-light-100);
  }

  th {
    position: sticky;
    top: 0;
    border-right: $tableBorder;
    padding: 0 16px;
    font-weight: normal;
    background-color: var(--color-light-100);

    &:last-child {
      border-right: none;
    }
  }

  tr {
    height: 44px;
    border-bottom: $tableBorder;

    &:hover>td:not(.emptyMessage, .loading) {
      color: var(--color-text-orange);
      background-color: var(--color-table-row-hover);
    }

    &:hover [data-hover="fill"] * {
      fill: var(--color-text-orange)
    }

    &:hover [data-hover="stroke"] * {
      stroke: var(--color-text-orange)
    }

    &:hover>td>div *, &:hover>td>span {
      color: var(--color-text-orange)
    }

    &:last-child {
      height: 100%
    }
  }

  td {
    @include mixins.endEllipsis; 
    padding: 0 16px;
  }

  .pointIcon {
    display: inline-flex;
    padding: 10px 0;

    &:hover {
      cursor: pointer;
    }
  }
}

.emptyMessage {
  font-size: 18px;
  text-align: center;
  color: var(--color-grey-90);
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.clickableCell {
  cursor: pointer;
}
