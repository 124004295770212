@use 'styles/mixins.scss';

.container {
  @include mixins.endEllipsis; 
  display: flex;
  align-items: center;
}

.ellipsis {
  @include mixins.endEllipsis;
}

.campaignCell {
  margin-left: 16px;
  cursor: pointer;
}

.bannerCell {
  margin-left: 15px;
}

.siteCell {
  font-weight: 600;
}

.eye {
  margin-left: 32px;
  flex-shrink: 0;
}

.arrow {
  margin-left: 10px;
  transition: transform 0.2s;
  cursor: pointer;
  flex-shrink: 0;
}

.expand {
  transform: rotate(180deg);
}