@use 'styles/mixins.scss';

.sidebar {
  height: calc(100vh - 20px);
  min-height: 630px;
  width: 250px;
  position: fixed;
  top: 10px;
  left: 10px;
  padding: 10px;
  border-radius: 8px;
  background-color: var(--color-light-90);
  box-shadow: 0 4px 18px rgba(134, 139, 157, 0.21);

  .header {
    padding: 10px;

    .container {
      display: flex;
      align-items: flex-end;
      width: fit-content;
      text-decoration: none;
    }

    .logo {
      margin-right: 20px;
      width: 41px;
    }

    .title {
      margin: 0;
      font-size: 22px;
      @include mixins.secondaryFont;
    }

    .profile {
      margin: 20px 0 10px;
      min-height: 18px;
      font-size: 14px;
      display: block;
      @include mixins.mainFont;
    }
  }

  .logout {
    position: absolute;
    bottom: 15px;
    width: calc(100% - 20px)
  }
}
