@use 'styles/mixins.scss';

.title {
  @include mixins.title;
}

.sortDirectionContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
}

.sortDirection {
  margin-left: 22px;
}

