@use 'styles/mixins.scss';

.container {
  @include mixins.input;
  position: relative;
  border-radius: 8px;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 45%;
    right: 14px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4.5px 0 4.5px;
    border-color: var(--color-grey-90) transparent transparent transparent;
    transition: transform 0.2s;
  }

  &.focused:after {
    transform: rotate(180deg);
  }

  &.withOptions:after {
    display: none;
  }
}

.values {
  padding-bottom: 3px;
  padding-right: 30px;
  min-height: 32px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 8px;
  background-color: var(--color-light-100);
}

.focused {
  border: 1px solid var(--color-input-focus);
}

.label {
  display: flex;
  align-items: center;
  min-height: 24px;
  color: var(--color-text-brown);
  background-color: var(--color-menuItem-hover);
  padding-left: 8px;
  border-radius: 5px;
  margin: 3px 6px 0 0;
}

.removeIcon {
  cursor: pointer;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  padding: 0 8px;
  fill: var(--color-text-orange)
}

.placeholder {
  color: var(--color-grey-90);
  pointer-events: none;
}

.input {
  @include mixins.mainFont;
  width: 10px;
  flex-grow: 1;
  border: none;
  outline: none;
  font-size: 16px;
}

.invalid {
  @include mixins.invalidInput;
}
