.head {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.align {
  &-right {
    justify-content: flex-end;
  }
  &-left {
    justify-content: flex-start;
  }
  &-center {
    justify-content: center;
  }
}