/* pt-sans-regular - latin */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../static/fonts/pt-sans/PTSans-Regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../static/fonts/pt-sans/PTSans-Regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* pt-sans-700 - latin */
@font-face {
  font-family: 'PT Sans-Bold';
  font-style: normal;
  font-weight: 700;
  src: url('../static/fonts/pt-sans/PTSans-Bold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../static/fonts/pt-sans/PTSans-Bold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* pt-sans-caption-regular - latin */
@font-face {
  font-family: 'PT Sans Caption';
  font-style: normal;
  font-weight: 400;
  src: url('../static/fonts/pt-sans-caption/PTSans-Caption.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../static/fonts/pt-sans-caption/PTSans-Caption.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* pt-sans-caption-700 - latin */
@font-face {
  font-family: 'PT Sans Caption-Bold';
  font-style: normal;
  font-weight: 700;
  src: url('../static/fonts/pt-sans-caption/PTSans-CaptionBold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../static/fonts/pt-sans-caption/PTSans-CaptionBold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* rubik-regular - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url('../static/fonts/rubik/Rubik-Regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../static/fonts/rubik/Rubik-Regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* rubik-700 - latin */
@font-face {
  font-family: 'Rubik-Bold';
  font-style: normal;
  font-weight: 700;
  src: url('../static/fonts/rubik/Rubik-Bold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../static/fonts/rubik/Rubik-Bold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
