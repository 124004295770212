@use 'styles/mixins.scss';

.container {
  @include mixins.standardForm;

  .tooltip {
    @include mixins.tooltip;
    position: absolute;
    width: 230px;
    left: 390px;
    top: 77px;
  }
}

.format {
  width: 620px;
  display: flex;
  justify-content: space-between;

  .formatLabel {

    &:first-child {
      margin-top: 36px;
    }

    width: 100%;
    margin-bottom: 12px;
    margin-top: 26px;
    font-size: 18px;
    text-align: left;
  }

  .size {
    display: flex;
    align-items: center;
  }

  .formatInput {
    width: 73px;
    margin-right: 8px;
    > div {
      width: auto;
    }
  }

  .template {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 230px;
    height: 90px;
    padding: 16px;
    background-color: var(--color-template-bg);
    border: 1px solid var(--color-border);
    border-radius: 8px;

    & label {
      margin-bottom: 15px;
    }
  }

  .widthLabel {
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 36px 0 12px;
    font-size: 18px;
  }

  .widthType {
    padding: 0px 8px;
  }

  .heightType {
    margin-left: 8px;
  }
}
