@use 'styles/mixins.scss';

.container {
  display: flex;
  gap: 26px;
  margin-bottom: 40px;
}

.sitesBlock {
  width: 310px;
}

.datesBlock {
  display: flex;
  flex-direction: column;
}

.quickDateTemplates, .dataPickerWrapper {
  display: flex;
  align-items: baseline;
  gap: 16px;
}

.label {
  line-height: 32px;
  margin-bottom: 9px;
}






